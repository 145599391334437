import React from 'react';
import PropTypes from 'prop-types';

const TextArea = props =>
  <textarea
    {...props}
    onChange={e => props.onChange(e.target.value)}
  />;

TextArea.defaultProps = {
  rows: 5
};

TextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.number,
  value: PropTypes.string
};

export default TextArea;