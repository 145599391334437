import React from 'react';
import FormField from '../../FormField';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';

const QuestionFour = ({choice}) =>
  <>
    <div className='form-field'>
      <label>What plans have you made to ease the return home?</label>
    </div>
    <FormField name='have-not-done'/>
    <FormField name='packed-for-hospital'/>
    <FormField name='asked-friends'/>
    <FormField name='prepared-home'/>
    <FormField name='planned-for-return'/>
    <NavigationButton
      pathLeft={choice === 'Yes' ? ROUTES.QUESTION_THREE : ROUTES.QUESTION_ONE}
      pathRight={ROUTES.QUESTION_FOUR_ANSWER}
    />
  </>;
QuestionFour.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'practiced-imagery'})(QuestionFour);