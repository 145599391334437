import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

const NavigationButton = props => {
  if (props.layout === 'left') {
    return (
      <>
        <div className='small button-group margin-0'>
          <a className='hollow disabled button' >
            <i className='fa fa-long-arrow-left fa-fw fa-2x'/>
          </a>
          <Link
            className='button'
            role='next'
            to={{
              pathname: props.pathRight
            }}>
            <i className='fa fa-long-arrow-right fa-fw fa-2x'/>
          </Link>
        </div>
      </>
    );
  }
  else if (props.layout === 'right') {
    return (
      <>
        <div className='small button-group margin-0'>
          <Link
            className='button'
            role='prev'
            to={{
              pathname: props.pathLeft
            }}>
            <i className='fa fa-long-arrow-left fa-fw fa-2x'/>
          </Link>
          <a className='hollow disabled button'>
            <i className='fa fa-long-arrow-right fa-fw fa-2x'/>
          </a>
        </div >
      </>
    );
  }

  return (
    <>
      <div className='small button-group margin-0'>
        <Link
          className='button'
          role='prev'
          to={{
            pathname: props.pathLeft
          }}>
          <i className='fa fa-long-arrow-left fa-fw fa-2x'/>
        </Link>
        <Link
          className='button'
          role='next'
          to={{
            pathname: props.pathRight
          }}>
          <i className='fa fa-long-arrow-right fa-fw fa-2x'/>
        </Link>
      </div>
    </>
  );
};

NavigationButton.propTypes = {
  layout: PropTypes.string,
  pathLeft: PropTypes.string,
  pathRight: PropTypes.string,
  userChoice: PropTypes.string
};

export default NavigationButton;