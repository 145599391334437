const ROUTES = {
  QUESTION_ONE: '/modules/coping-with-worry/assignments/review',
  QUESTION_ONE_ANSWER: '/modules/coping-with-worry/assignments/review/question-one-answer',
  QUESTION_TWO: '/modules/coping-with-worry/assignments/review/question-two',
  QUESTION_TWO_ANSWER: '/modules/coping-with-worry/assignments/review/question-two-answer',
  QUESTION_THREE: '/modules/coping-with-worry/assignments/review/question-three',
  QUESTION_THREE_ANSWER: '/modules/coping-with-worry/assignments/review/question-three-answer',
  QUESTION_FOUR: '/modules/coping-with-worry/assignments/review/question-four',
  QUESTION_FOUR_ANSWER: '/modules/coping-with-worry/assignments/review/question-four-answer'
};

export default ROUTES;