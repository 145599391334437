import React from 'react';
import FormField from '../../FormField';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';

const QuestionTwo = () =>
  <>
    <FormField name='deep-breathing-scale'/>
    <NavigationButton
      pathLeft={ROUTES.QUESTION_ONE}
      pathRight={ROUTES.QUESTION_TWO_ANSWER}/>
  </>;

export default Question({})(QuestionTwo);