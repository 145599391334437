import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import PropTypes from 'prop-types';
import Question from '../../Question';
import {getNumberFromString} from '../../../utils';

const QuestionThreeAnswer = ({choice}) =>
  <>
    {getNumberFromString(choice) >= 5 ?
      <p>
        It's great that Deep Breathing has been helpful for you. You may notice
        that using Deep Breathing every day will increase your overall feeling
        of well-being and sense of calm. Keep using this skill daily since it
        works for you!
      </p>
      :
      <p>
        It sounds like Deep Breathing has not been very helpful for you so far.
        Throughout this program, you will learn several strategies to reduce
        stress and worry. We encourage you to keep practicing to see how this
        strategy will work for you.
      </p>
    }

    <NavigationButton
      pathLeft={ROUTES.QUESTION_THREE}
      pathRight={ROUTES.QUESTION_FOUR}
    />
  </>;
QuestionThreeAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'deep-breathing-reduce-stress'})(QuestionThreeAnswer);