import React, {useState, useEffect} from 'react';
import NavigationButton from '../../NavigationButton';
import CompleteButton from '../../CompleteButton';
import ROUTES from './route';
import PropTypes from 'prop-types';
import Question from '../../Question';
import Rails from '@rails/ujs';

const QuestionFourAnswer = ({choice, additionalFields}) => {
  const [userMantra, setUserMantra] = useState('');

  useEffect(() => {
    Rails.ajax({
      url: '/form/fields/mantra',
      type: 'get',
      success: field => {
        setUserMantra(field.value);
      }
    });
  }, []);

  const additionalFieldsAreNull = !additionalFields.some(element => element.value !== null);

  return (
    <>
      {choice === 'true' || additionalFieldsAreNull ?
        <p>
                We understand that taking action to prepare can take time and effort.
                However, making practical preparations and asking for the help you need
                now will be much easier than when you are in the middle of everything.
                Review this module again for practical, easy ways you can get organized
                for the hospital and your teen&apos;s return home.
        </p>
        :
        <p>
                It sounds like preparations are going well! You took an important step
                getting prepared ahead of time.
        </p>
      }

      <p>
        <b>
                You have now completed the SurgeryPal before surgery program! Remember you
                can come back and re-read any of the content in this program if you want a
                refresher on a skill or tip. We wish you and your teen all the best with
                the surgery! Remember {userMantra}!
        </b>
      </p>

      <CompleteButton/>
      <NavigationButton
        layout='right'
        pathLeft={ROUTES.QUESTION_FOUR}/>
    </>
  );
};

QuestionFourAnswer.propTypes = {
  additionalFields: PropTypes.array,
  choice: PropTypes.string
};

export default Question({
  dependentFieldName: 'have-not-done',
  additionalFields: ['packed-for-hospital', 'asked-friends', 'planned-for-return', 'prepared-home']
})(QuestionFourAnswer);