import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';

class MultiTextInput extends Component {
  state = {
    values: this.props.value && this.props.value.split(',') || ['']
  };

  addField = index => {
    const {values} = this.state;
    const newValues = [...values.slice(0, index + 1), '', ...values.slice(index + 1)];
    this.setState({values: newValues});
    this.props.onChange(newValues.join(','));
  };

  editField = (value, index) => {
    const {values} = this.state;
    const newValues = [...values.slice(0, index), value, ...values.slice(index + 1)];
    this.setState({values: newValues});
    this.props.onChange(newValues.join(','));
  };

  removeField = index => {
    const {values} = this.state;
    const newValues = [...values.slice(0, index), ...values.slice(index + 1)];
    this.setState({values: newValues});
    this.props.onChange(newValues.join(','));
  };

  render() {
    const {values} = this.state;
    return <>
      {values.map((value, index) =>
        <div
          className='input-group'
          key={index}>
          <TextInput
            {...this.props}
            id={index}
            onChange={val => this.editField(val, index)}
            value={value}/>
          <div className='input-group-button'>
            <button
              className='hollow warning button'
              disabled={values.length <= 1}
              onClick={() => this.removeField(index)}
              type='button'>
              <i className='fa fa-minus'/>
            </button>
            <button
              className='hollow primary button'
              onClick={() => this.addField(index)}
              type='button'>
              <i className='fa fa-plus'/>
            </button>
          </div>
        </div>)}
    </>;
  }
}

MultiTextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default MultiTextInput;