import React from 'react';
import FormField from '../../FormField';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import PropTypes from 'prop-types';
import Question from '../../Question';

const QuestionFour = ({choice}) =>
  <>
    <p>Which self-care activities did you do this week?</p>

    <FormField name='get-enough-sleep-or-not'/>
    <FormField name='no-phone-during-dinner-or-not'/>
    <FormField name='promote-healthy-diet-or-not'/>
    <FormField name='no-emails-at-night-or-not'/>
    <FormField name='exercise-twice-a-week-or-not'/>
    <FormField name='follow-up-with-medical-care-or-not'/>
    <FormField name='use-relaxation-or-medication-or-not'/>
    <FormField name='spend-time-with-friends-and-family-or-not'/>
    <FormField name='do-at-least-one-relaxing-activity-or-not'/>
    <FormField name='pleasurable-activity-1'/>
    <FormField name='pleasurable-activity-2'/>
    <NavigationButton
      pathLeft={choice === 'Yes' ? ROUTES.QUESTION_THREE : ROUTES.QUESTION_ONE}
      pathRight={ROUTES.QUESTION_FOUR_ANSWER}
    />
  </>;

QuestionFour.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'mindful-breathing'})(QuestionFour);