import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import PropTypes from 'prop-types';
import Question from '../../Question';
import {getNumberFromString} from '../../../utils';

const QuestionThreeAnswer = ({choice}) =>
  <>
    {getNumberFromString(choice) >= 5 ?
      <p>
        That's great that worry time has been helpful for you. You may have
        found that worry time and other skills work better the more you use
        them, so it is important to set aside a time each day to practice.
      </p>
      :
      <p>
        It sounds like worry time has not yet been helpful for you. We encourage
        you to try all of the skills that you've learned in this program to find
        the ones that work best for you. If this skill doesn't fit well with
        you, another option is to use physical strategies (such as mindful
        breathing, yoga, or exercise) when you catch yourself worrying.
      </p>
    }

    <NavigationButton
      pathLeft={ROUTES.QUESTION_THREE}
      pathRight={ROUTES.QUESTION_FOUR}
    />
  </>;
QuestionThreeAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({
  dependentFieldName: 'worry-time-reduce-anxiety-or-stress'
})(QuestionThreeAnswer);