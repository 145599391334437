import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';
import {getNumberFromString} from '../../../utils';

const QuestionTwoAnswer = ({choice}) =>
  <>
    {getNumberFromString(choice) >= 5 ?
      <p>
        It sounds like using worry time was easy for you. Great job making the
        time to practice! The more you practice this skill, the easier it will
        be for you to balance your day and begin to lessen your attention to
        stressful or anxious thoughts.
      </p>
      :
      <p>
        It sounds like using worry time was difficult for you. It might be that
        you chose a time that did not work well to focus on this strategy or it
        might be that you were not able to generate anxious thoughts. Consider
        what made worry time challenging this week to use and see if you can
        make some changes to try it out again this week.
      </p>
    }

    <NavigationButton
      pathLeft={ROUTES.QUESTION_TWO}
      pathRight={ROUTES.QUESTION_THREE}
    />
  </>;
QuestionTwoAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'how-easy-worry-time'})(QuestionTwoAnswer);