import React from 'react';
import NavigationButton from '../../NavigationButton';
import CompleteButton from '../../CompleteButton';
import ROUTES from './route';
import PropTypes from 'prop-types';
import Question from '../../Question';

const QuestionFourAnswer = ({choice}) =>
  <>
    {choice === 'Yes' ?
      <p>
          Congratulations for talking with your teen about surgery! It can be
          challenging to find the right time or place, or even to start the conversation,
          but having a space where your teen can safely express worries and fears can help
          them cope better.
      </p>
      :
      <>
        <p>
              We know you may have a limited amount of time to talk with your teen each day,
              which is why it&apos;s so important to increase opportunities to communicate. Try
              finding (or creating) a time and place where your teen has your full attention,
              with limited distractions and ample time (car rides can present good opportunities).
              Even if it's uncomfortable in the moment, giving your teen the chance to express her
              emotions will help her to process things and cope better in the upcoming weeks. Here
              are some examples of conversation starters:
        </p>
        <ul>
          <li>&quot;How are you feeling about your surgery?&quot;</li>
          <li>&quot;I&apos;ve been thinking a lot about your surgery. What kinds of thoughts have
              you been having?&quot;
          </li>
        </ul>
      </>}
    <CompleteButton/>
    <NavigationButton
      layout='right'
      pathLeft={ROUTES.QUESTION_FOUR}/>
  </>;

QuestionFourAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'talk-about-surgery'})(QuestionFourAnswer);