import {NOT_FOUND} from './constants';

export const getNumberFromString = string => {
  let extractedFormFieldValue = string;

  if (string === null || string === NOT_FOUND) {
    const lowestNumberString = '0';
    extractedFormFieldValue = lowestNumberString;
  }

  const numbersArray = extractedFormFieldValue.match(/\d+/g).map(Number);
  return numbersArray[0];
};