import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import QuestionOne from './QuestionOne';
import QuestionOneAnswer from './QuestionOneAnswer';
import QuestionTwo from './QuestionTwo';
import QuestionTwoAnswer from './QuestionTwoAnswer';
import QuestionThree from './QuestionThree';
import QuestionThreeAnswer from './QuestionThreeAnswer';
import QuestionFour from './QuestionFour';
import QuestionFourAnswer from './QuestionFourAnswer';
import styled from 'styled-components';
import ROUTES from './route';

const PageContainer = styled.div `
  position: relative;
`;

const Review = () =>
  <Router>
    <Route
      render={({location}) =>
        <PageContainer>
          <TransitionGroup component={null}>
            <CSSTransition
              classNames='page'
              key={location.key}
              timeout={300}
            >
              <Routes location={location}>
                <Route
                  component={QuestionFourAnswer}
                  exact
                  path={ROUTES.QUESTION_FOUR_ANSWER}/>
                <Route
                  component={QuestionFour}
                  exact
                  path={ROUTES.QUESTION_FOUR}/>
                <Route
                  component={QuestionThreeAnswer}
                  exact
                  path={ROUTES.QUESTION_THREE_ANSWER}/>
                <Route
                  component={QuestionThree}
                  exact
                  path={ROUTES.QUESTION_THREE}/>
                <Route
                  component={QuestionTwoAnswer}
                  exact
                  path={ROUTES.QUESTION_TWO_ANSWER}/>
                <Route
                  component={QuestionTwo}
                  exact
                  path={ROUTES.QUESTION_TWO}/>
                <Route
                  component={QuestionOneAnswer}
                  exact
                  path={ROUTES.QUESTION_ONE_ANSWER}/>
                <Route
                  component={QuestionOne}
                  exact
                  path={ROUTES.QUESTION_ONE}/>
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </PageContainer>
      }
    />
  </Router>;

export default Review;