import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';
import {getNumberFromString} from '../../../utils';

const QuestionTwoAnswer = ({choice}) =>
  <>
    {getNumberFromString(choice) >= 5 ?
      <p>
        It sounds like using mindful breathing was easy for you. Great job
        making the time to practice! The more you practice this skill, the
        easier it will be for you to redirect your attention to the present
        moment when you are feeling stressed or anxious.
      </p>
      :
      <p>
        It sounds like using mindful breathing was difficult for you. Many
        parents find that the easiest way to learn mindful breathing is to
        choose a regular time to practice. Often people have the most negative
        thoughts or worries at bedtime. Try listening to the mindful breathing
        audio before you fall asleep – this will help to calm your mind and make
        it easier to fall asleep.
      </p>
    }

    <NavigationButton
      pathLeft={ROUTES.QUESTION_TWO}
      pathRight={ROUTES.QUESTION_THREE}
    />
  </>;
QuestionTwoAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({
  dependentFieldName: 'how-easy-mindful-breathing-skill'
})(QuestionTwoAnswer);