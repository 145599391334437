import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';
import {getNumberFromString} from '../../../utils';

const QuestionTwoAnswer = ({choice}) =>
  <>
    {getNumberFromString(choice) >= 5 ?
      <p>
        It sounds like using Deep Breathing was easy for you. That's great! Deep
        Breathing is one of the easiest ways to cope with stress and can be used
        before surgery, in the hospital, and at home after surgery. Continuing
        to practice will help you master your stress levels.
      </p>
      :
      <p>
        It sounds like using Deep Breathing was difficult for you. Don't give
        up! Learning to use Deep Breathing is a lot like riding a bike or
        playing a musical instrument for the first time. If you keep practicing,
        there will come a time when your muscle memory will kick in and you'll
        know exactly what to do, without much effort. Practice relaxation at a
        regular time each day (such as while commuting to work or at your desk
        at work) to engrain it into your daily routine – this way it will become
        easier to use, like a habit.
      </p>
    }

    <NavigationButton
      pathLeft={ROUTES.QUESTION_TWO}
      pathRight={ROUTES.QUESTION_THREE}
    />
  </>;
QuestionTwoAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'deep-breathing-scale'})(QuestionTwoAnswer);