import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-danger */

const RadioButton = props =>
  <div className='grid-x grid-margin-x margin-left'>
    {Object.keys(props.options).map(key =>
      <div
        className='shrink small-font'
        key={key}>
        <input
          {...props}
          checked={props.value === props.options[key]}
          id={key}
          onChange={() => props.onChange(props.options[key])}
          type='radio'
          value={props.options[key]}
        />
        <label htmlFor={key}>{props.options[key]}</label>
      </div>)}
  </div>;

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object,
  value: PropTypes.string
};

export default RadioButton;