import React from 'react';
import QuestionTwo from './QuestionTwo';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';

const QuestionOneAnswer = ({choice}) =>
  choice === 'Yes' ?
    <QuestionTwo/>
    : <>
      <p>
            It sounds like it might be a challenge for you to practice Deep Breathing. Remember,
            Deep Breathing is a very effective way to reduce stress and anxiety. Like most new skills,
            it takes practice, especially when first starting out. You may find that you can practice
            easily while commuting to work, watching television, or sitting at your desk at work.
            The more you practice Deep Breathing, the easier it will be to then use this skill when
            you are experiencing stress.
      </p>
      <NavigationButton
        pathLeft={ROUTES.QUESTION_ONE}
        pathRight={ROUTES.QUESTION_FOUR}/>
    </>;

QuestionOneAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'deep-breathing'})(QuestionOneAnswer);