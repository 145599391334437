import React from 'react';
import FormField from '../../FormField';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';

const QuestionOne = () =>
  <>
    <FormField name='worry-time'/>
    <NavigationButton
      layout='left'
      pathRight={ROUTES.QUESTION_ONE_ANSWER}/>
  </>;

export default Question({})(QuestionOne);