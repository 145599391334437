import React from 'react';
import PropTypes from 'prop-types';

const TextInput = props =>
  <input
    {...props}
    onChange={e => props.onChange(e.target.value)}
    type={props.type || 'text'}
    value={props.value || ''}
  />;

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.string
};

export default TextInput;