/* eslint-env node */
/*
 * This file is automatically compiled by Webpack, along with any other files
 * present in this directory. You're encouraged to place your actual application logic in
 * a relevant structure within app/javascript and only use these pack files to reference
 * that code so it'll be compiled.
 */

/* global Turbolinks:true */
import $ from 'jquery';
import Foundation from 'foundation-sites'; // eslint-disable-line no-unused-vars
import Fresco from '@staaky/fresco'; // eslint-disable-line no-unused-vars
import PACKAGE_JSON from '../../../package.json';
import Rails from '@rails/ujs';

require('turbolinks').start();

Rails.start();

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

if (PACKAGE_JSON.engines.node >= '18') {
  throw new Error(`Wrong node version. Expected: '< 18'. Actual: ${PACKAGE_JSON.engines.node}.
  The production server uses CentOS 7.9, which does not support node >= 18.\n
  CentOS 7.9 has an EOL of June 30, 2024, at which point it should be upgraded.\n
  Once CentOS is upgraded to a later version, node should be upgraded as well.`);
}

document.addEventListener('turbolinks:load', () => {
  $(document).foundation();
});

/*
 * Uncomment to copy all static images under ../images to the output folder and reference
 * them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
 * or the `imagePath` JavaScript helper below.
 *
 * const images = require.context('../images', true)
 * const imagePath = (name) => images(name, true)
 */

/* Fix a bug in Rails where error messages do not show when using Turbolinks */
document.addEventListener('ajax:complete', event => {
  let referrer, snapshot;
  const xhr = event.detail[0];

  if ((xhr.getResponseHeader('Content-Type') || '').substring(0, 9) === 'text/html') {
    referrer = window.location.href;
    snapshot = Turbolinks.Snapshot.wrap(xhr.response);
    Turbolinks.controller.cache.put(referrer, snapshot);
    return Turbolinks.visit(referrer, {
      action: 'restore'
    });
  }

  return true;
}, false);// Support component names relative to this directory:

// automatically play audio files when a reveal dialog is open and stop when closed
$(document).on('open.zf.reveal', '[data-reveal]', event => {
  $(event.target).find('audio').each((index, audio) => {
    audio.currentTime = 0;
    audio.play();
  });
});

$(document).on('closed.zf.reveal', '[data-reveal]', event => {
  $(event.target).find('audio').each((index, audio) => audio.pause());
});