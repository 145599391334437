import React from 'react';

export const CompleteButton = () =>
  <div className='margin-top-3 margin-bottom-3'>
    <a
      className='button'
      data-method='post'
      data-remote='true'
      href={`${location.pathname.substring(0, location.pathname.lastIndexOf('/') + 1)}complete`}
    >
        Complete Review <i className='fa fa-arrow-circle-o-right'/>
    </a>
  </div>;

export default CompleteButton;