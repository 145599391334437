import React from 'react';
import FormField from '../../FormField';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';

const QuestionFour = ({choice, additionalField}) =>
  <>
    <FormField name='talk-about-surgery'/>
    <NavigationButton
      pathLeft={
        choice === 'true' || additionalField === 'true'
          ? ROUTES.QUESTION_THREE
          : ROUTES.QUESTION_ONE
      }
      pathRight={ROUTES.QUESTION_FOUR_ANSWER}
    />
  </>;
QuestionFour.propTypes = {
  additionalField: PropTypes.string,
  choice: PropTypes.string
};

export default Question({
  additionalField: 'thought-replacement',
  dependentFieldName: 'letting-go'
})(QuestionFour);