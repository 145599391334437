import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Rails from '@rails/ujs';
import Spinner from './Spinner';
import {Slider} from './Slider';

const Question = props => WrappedComponent =>
  class extends Component {
    state = {field: null, error: false, additionalFields: []};

    componentDidMount() {
      if (props.dependentFieldName) {
        this.getField(props.dependentFieldName);
      }

      if (props.additionalFields) {
        this.getAdditionalField(props.additionalFields);
      }
    }

    getField = name => {
      Rails.ajax({
        url: `/form/fields/${name}`,
        type: 'get',
        success: field => {
          this.setState({field: field});
        },
        error: () => {
          this.setState({error: true});
        }
      });
    };

    getAdditionalField = fields => {
      const requestParam = fields.join();
      Rails.ajax({
        url: `/form/fields${fields.length > 1 ? `?names=${requestParam}` : `/${requestParam}`}`,
        type: 'get',
        success: result => {
          this.setState({additionalFields: Array.isArray(result) ? result : [result]});
        },
        error: () => {
          this.setState({error: true});
        }
      });
    };

    render() {
      const {error, field, additionalFields} = this.state;

      if (error) {
        return (
          <Slider>
            <div className='alert callout'>
              <b>ERROR:</b> Form field &apos;{props.name}&apos; was not found.
            </div>
          </Slider>
        );
      }
      else if (props.dependentFieldName && field === null) {
        return (
          <Slider>
            <Spinner/>
          </Slider>
        );
      }

      if (additionalFields) {
        return (
          <Slider>
            <WrappedComponent
              additionalFields={additionalFields ? additionalFields : null}
              choice={field ? field.value : null}
            />
          </Slider>
        );
      }

      return (
        <Slider>
          <WrappedComponent choice={field ? field.value : null}/>
        </Slider>
      );
    }
  };

Question.propTypes = {
  additionalFields: PropTypes.array,
  dependentFieldName: PropTypes.string
};

export default Question;