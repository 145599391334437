import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {notify} from 'react-notify-toast';
import Rails from '@rails/ujs';
import Spinner from './Spinner';
import TextArea from './form_fields/TextArea';
import TextInput from './form_fields/TextInput';
import CheckBox from './form_fields/CheckBox';
import RadioButton from './form_fields/RadioButton';
import MultiTextInput from './form_fields/MultiTextInput';
import {NOT_FOUND} from '../constants';

export default class FormField extends Component {
  state = {field: null, error: false};

  componentDidMount() {
    this.getField();
  }

  componentWillUnmount() {
    const {field} = this.state;

    if (field && field.value === NOT_FOUND) {
      this.updateField();
    }
  }

  getField = () => {
    Rails.ajax({
      url: `/form/fields/${this.props.name}`,
      type: 'get',
      success: field => {
        this.setState({field});
      },
      error: () => {
        this.setState({error: true});
      }
    });
  };

  updateField = value => {
    Rails.ajax({
      url: `/form/fields/${this.props.name}`,
      type: 'patch',
      data: `value=${value && value !== NOT_FOUND ? value : ''}`,
      error: error => {
        notify.show(error, 'error');
      }
    });
  };

  onChange = value => {
    clearTimeout(this.timer);
    this.setState({field: {...this.state.field, value}});
    this.timer = setTimeout(() => this.updateField(value), 250);
  };

  renderField = () => {
    const {field} = this.state;
    const props = {
      ...field,
      ...this.props,
      onChange: this.onChange,
      value: (field.value && field.value !== NOT_FOUND ? field.value : '').toString()
    };

    switch (field.kind) {
    case 'CHECK_BOX':
      return <CheckBox {...props}/>;
    case 'MULTI_TEXT_INPUT':
      return <MultiTextInput {...props}/>;
    case 'TEXT_AREA':
      return <TextArea {...props}/>;
    case 'TEXT_INPUT':
      return <TextInput {...props}/>;
    case 'RADIO_BUTTON':
      return <RadioButton {...props}/>;

    default:
      return null;
    }
  };

  render() {
    const {error, field} = this.state;

    if (error) {
      return (
        <div className='alert callout'>
          <b>ERROR:</b> Form field &apos;{this.props.name}&apos; was not found.
        </div>
      );
    }
    else if (field === null) {
      return <Spinner/>;
    }

    /* eslint-disable react/no-danger */
    return (
      <div className='form-field'>
        {field.label && field.kind !== 'CHECK_BOX' && <label
          dangerouslySetInnerHTML={{__html: field.label}}
          htmlFor={name}
        />}
        {this.renderField()}
      </div>
    );
    /* eslint-enable react/no-danger */
  }
}

FormField.propTypes = {
  name: PropTypes.string.isRequired
};