import React from 'react';
import QuestionTwo from './QuestionTwo';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';

const QuestionOneAnswer = ({choice}) =>
  choice === 'Yes' ?
    <QuestionTwo/>
    : <>
      <p>
        You haven't yet practiced mindful breathing. Remember, the more you practice this
        skill, the easier it will be for you to redirect your attention to the present moment
        when you are feeling stressed or anxious. This skill can become an important part of
        your self- care!
      </p>

      <NavigationButton
        pathLeft={ROUTES.QUESTION_ONE}
        pathRight={ROUTES.QUESTION_FOUR}/>
    </>;

QuestionOneAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'mindful-breathing'})(QuestionOneAnswer);