import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import PropTypes from 'prop-types';
import Question from '../../Question';
import {getNumberFromString} from '../../../utils';

const QuestionThreeAnswer = ({choice}) =>
  <>
    {getNumberFromString(choice) >= 5 ?
      <p>
        That's great that mindful breathing has been helpful for you. You may
        have found that mindful breathing and other relaxation skills work
        better the more you use them, so it is important to set aside a few
        minutes each day to practice.
      </p>
      :
      <p>
        It sounds like mindful breathing has not yet been helpful for you. We
        encourage you to try all of the relaxation skills that you've learned in
        this program to find the ones that work best for you. Most people just
        need to find one relaxation skill that fits them best.
      </p>
    }

    <NavigationButton
      pathLeft={ROUTES.QUESTION_THREE}
      pathRight={ROUTES.QUESTION_FOUR}
    />
  </>;
QuestionThreeAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({
  dependentFieldName: 'mindful-breathing-reduce-stress'
})(QuestionThreeAnswer);