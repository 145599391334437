import React from 'react';
import FormField from '../../FormField';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';

const QuestionFour = ({choice}) =>
  <>
    <FormField name='support-teen-activity'/>
    <NavigationButton
      pathLeft={choice === 'Yes' ? ROUTES.QUESTION_THREE : ROUTES.QUESTION_ONE}
      pathRight={ROUTES.QUESTION_FOUR_ANSWER}/>
  </>;

QuestionFour.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'worry-time'})(QuestionFour);