import React from 'react';
import NavigationButton from '../../NavigationButton';
import CompleteButton from '../../CompleteButton';
import ROUTES from './route';

const QuestionFourAnswer = () =>
  <>
    <p>
      Remember to come back to this lesson if you need any more tips in supporting your
      teen's activity or school participation
    </p>
    <CompleteButton/>
    <NavigationButton
      layout='right'
      pathLeft={ROUTES.QUESTION_FOUR}/>
  </>;

export default QuestionFourAnswer;