import React from 'react';
import NavigationButton from '../../NavigationButton';
import CompleteButton from '../../CompleteButton';
import ROUTES from './route';
import PropTypes from 'prop-types';
import Question from '../../Question';

const QuestionFourAnswer = ({choice}) =>
  <>
    {choice === 'Yes' ?
      <p>
          You've been able to gather information about your teen's surgery.
          That's great! Families often tell us that getting detailed information is
          comforting and can help reduce stress. Remember that if new questions come up,
          you can always take your list of questions to your teen's pre-operative appointment
          or call the surgery clinic.
      </p> :
      <p>
        You haven't yet been able to gather information about your teen's surgery.
        Remember to think about all the questions you have about surgery using the
        exercise in this module, and then take your list of questions to your teen's
        pre-operative appointment or call the surgery clinic if your questions weren't
        answered.
      </p>}
    <CompleteButton/>
    <NavigationButton
      layout='right'
      pathLeft={ROUTES.QUESTION_FOUR}/>
  </>;

QuestionFourAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'more-information'})(QuestionFourAnswer);