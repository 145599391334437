import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import PropTypes from 'prop-types';
import Question from '../../Question';
import {getNumberFromString} from '../../../utils';

const QuestionThreeAnswer = ({choice}) =>
  <>
    {getNumberFromString(choice) >= 5 ?
      <p>
        It's a good sign that thinking strategies have been helpful for you. It
        is important to continue to practice and master these skills. Keep it
        up!
      </p>
      :
      <p>
        It sounds like thinking strategies have not yet been very helpful for
        you. This is completely normal. If you were playing baseball, would you
        expect to hit the ball during the game if you had never swung a bat
        before? Of course not! Regular practice is essential to experience the
        powerful benefits of the thinking strategies. Visit SurgeryPal to review
        and practice your skills any time!
      </p>
    }

    <NavigationButton
      pathLeft={ROUTES.QUESTION_THREE}
      pathRight={ROUTES.QUESTION_FOUR}
    />
  </>;
QuestionThreeAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'reduce-worry-scale'})(QuestionThreeAnswer);