import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';

const QuestionOneAnswer = props => {
  const {choice, additionalFields} = props;

  const valueOfAdditionalField = additionalFields[0] ? additionalFields[0].value : null;

  return (
    <>
      {choice === 'true' || valueOfAdditionalField === 'true' ?
        <p>
          Great job! Thinking skills can be very helpful for reducing worry. The
          more you use them, the bigger the benefits for you and your teen.
        </p>
        :
        <p>
          It sounds like you haven't had a chance to practice the thinking
          strategies this week. This might be because you haven't had any
          worries or it might be because you haven't had time. Thinking skills
          can be very helpful for reducing worry. Remember to set aside time
          this week to practice. You can go back to review this module if you
          need a refresher!
        </p>
      }

      <NavigationButton
        pathLeft={ROUTES.QUESTION_ONE}
        pathRight={
          choice === 'true' || valueOfAdditionalField === 'true'
            ? ROUTES.QUESTION_TWO
            : ROUTES.QUESTION_FOUR
        }
      />
    </>
  );
};

QuestionOneAnswer.propTypes = {
  additionalFields: PropTypes.array,
  choice: PropTypes.string
};

export default Question({
  additionalFields: ['thought-replacement'],
  dependentFieldName: 'letting-go'
})(QuestionOneAnswer);