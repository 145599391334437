import React from 'react';
import FormField from '../../FormField';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';

const QuestionThree = () =>
  <>
    <FormField name='reduce-worry-scale'/>
    <NavigationButton
      pathLeft={ROUTES.QUESTION_TWO}
      pathRight={ROUTES.QUESTION_THREE_ANSWER}/>
  </>;

export default Question({})(QuestionThree);