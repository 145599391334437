import React, {useState, useEffect} from 'react';
import NavigationButton from '../../NavigationButton';
import CompleteButton from '../../CompleteButton';
import ROUTES from './route';
import Rails from '@rails/ujs';

const QuestionFourAnswer = () => {
  const [choice, setchoice] = useState('');

  const selfCareActivities = [
    'get-enough-sleep-or-not',
    'no-phone-during-dinner-or-not',
    'promote-healthy-diet-or-not',
    'no-emails-at-night-or-not',
    'exercise-twice-a-week-or-not',
    'follow-up-with-medical-care-or-not',
    'use-relaxation-or-medication-or-not',
    'spend-time-with-friends-and-family-or-not',
    'do-at-least-one-relaxing-activity-or-not'
  ];

  const getField = name => {
    Rails.ajax({
      url: `/form/fields/${name}`,
      type: 'get',
      success: field => {
        if (field.value === 'true') {
          setchoice(field.value);
        }
      }
    });
  };

  const renderChoice = arr => {
    for (let i = 0; i < arr.length; i++) {
      getField(arr[i]);
    }
  };

  useEffect(() => {
    renderChoice(selfCareActivities);
  }, []);

  return (
    <>
      {choice === 'true' ?
        <p>
          That's great! Keep up the self-care activities and make them a part of your daily routine.
        </p> :
        <p>
          It can be challenging to find time for your own health and well-being when you already
          are trying to balance caring for your teen with your other home, family and work
          responsibilities. Remember the first step in caring for others is caring for yourself.
          Plan on doing at least one self-care activity every day, whether it's taking a walk
          aound the block or spending 20 minutes unwinding.
        </p>}
      <CompleteButton/>
      <NavigationButton
        layout='right'
        pathLeft={ROUTES.QUESTION_FOUR}/>
    </>
  );
};

export default QuestionFourAnswer;