import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import PropTypes from 'prop-types';
import Question from '../../Question';
import {getNumberFromString} from '../../../utils';

const QuestionThreeAnswer = ({choice}) =>
  <>
    {getNumberFromString(choice) >= 5 ?
      <p>
        It&apos;s a good sign that imagery has been helpful for you. Imagery is
        a great skill to continue to master, because it can be used anytime to
        reduce stress, using the sole power of your imagination! Remember to use
        imagery in the hospital as well.
      </p>
      :
      <p>
        It sounds like imagery hasn&apos;t yet been helpful for you. With
        imagery, you use all five of your senses – vision, touch, taste, sound,
        and smell – to build images in your mind. In order for imagery to work
        the best, focus on each of the five senses in great detail. The more
        involved you can become in the scene you create, the harder it will be
        to pay attention to other uncomfortable thoughts and sensations.
      </p>
    }

    <NavigationButton
      pathLeft={ROUTES.QUESTION_THREE}
      pathRight={ROUTES.QUESTION_FOUR}
    />
  </>;
QuestionThreeAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'reduce-stress-scale'})(QuestionThreeAnswer);