import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';

const QuestionOneAnswer = ({choice}) =>
  <>
    {choice === 'Yes' ?
      <p>
                  Excellent! The more techniques you learn, the more tools you will have
                  to cope with stress. Keep practicing your skills daily while you wait
                  for surgery.
      </p>
      :
      <p>
                  You can gain skill in using imagery by practicing for a few minutes
                  every day. This way when you really need it most, you will be familiar
                  with it and be able to apply the skill quickly. Remember, you can go to
                  the home page of the program to listen to this audio as often as you like.
      </p>
    }

    <NavigationButton
      pathLeft={ROUTES.QUESTION_ONE}
      pathRight={choice === 'Yes' ? ROUTES.QUESTION_TWO : ROUTES.QUESTION_FOUR}/>
  </>;

QuestionOneAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'practiced-imagery'})(QuestionOneAnswer);