import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';
import {getNumberFromString} from '../../../utils';

const QuestionTwoAnswer = ({choice}) =>
  <>
    {getNumberFromString(choice) >= 5 ?
      <p>
        We&apos;re happy to hear that imagery has been easy for you to use!
        Imagery is a great relaxation technique for reducing stress, and can be
        a nice option for you to use as a form of distraction in the hospital.
      </p>
      :
      <p>
        It sounds like using imagery has been difficult for you. It will become
        easier to use imagery if you practice for a few minutes every day. This
        way when you really need it most, you can easily and quickly apply it.
        It might help to choose a &quot;happy place&quot; that is the most
        relaxing scene you can imagine. You can use a time and place when you
        felt wonderful and relaxed, a vividly-described scene from a book you
        love, or you can imagine a place you&apos;ve always wanted to visit.
      </p>
    }

    <NavigationButton
      pathLeft={ROUTES.QUESTION_TWO}
      pathRight={ROUTES.QUESTION_THREE}
    />
  </>;
QuestionTwoAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'imagery-skill-scale'})(QuestionTwoAnswer);