import React from 'react';
import QuestionTwo from './QuestionTwo';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';

const QuestionOneAnswer = ({choice}) =>
  choice === 'Yes' ?
    <QuestionTwo/>
    : <>
      <p>
        You haven't yet practiced scheduling a worry time. Remember, this strategy is
        meant to be used as a way of directing your attention to worries or anxious
        thoughts only once during the day to give you more balance. This skill can
        become an important part of your self-care!
      </p>

      <NavigationButton
        pathLeft={ROUTES.QUESTION_ONE}
        pathRight={ROUTES.QUESTION_FOUR}/>
    </>;

QuestionOneAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'worry-time'})(QuestionOneAnswer);