import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-danger */
const CheckBox = props =>
  <>
    <input
      {...props}
      checked={props.value === 'true'}
      id={props.name}
      onChange={e => props.onChange(e.target.checked)}
      type='checkbox'
      value={false}
    />
    <label
      dangerouslySetInnerHTML={{__html: props.label}}
      htmlFor={props.name}
    />
  </>;
/* eslint-enable react/no-danger */

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default CheckBox;