import React from 'react';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';
import PropTypes from 'prop-types';
import {getNumberFromString} from '../../../utils';

const QuestionTwoAnswer = ({choice}) =>
  <>
    {getNumberFromString(choice) >= 5 ?
      <p>
        It's great that using positive thinking skills is easy for you. You may
        find that using one thinking skill is easier than using the other.
        Continue to practice the thinking skills that work best for you!
      </p>
      :
      <p>
        It sounds like using thinking skills was difficult for you. This is
        totally normal – learning and mastering thinking skills often takes time
        and practice. Next time that you notice that you're stressed or feel
        anxious, try writing or journaling your thoughts. Then listen to the
        “letting go” audio, or practice replacing your thoughts with more
        balanced or neutral thoughts. The more you practice, the easier it will
        become to use these skills.
      </p>
    }

    <NavigationButton
      pathLeft={ROUTES.QUESTION_TWO}
      pathRight={ROUTES.QUESTION_THREE}
    />
  </>;
QuestionTwoAnswer.propTypes = {
  choice: PropTypes.string
};

export default Question({dependentFieldName: 'thinking-strategies-scale'})(QuestionTwoAnswer);