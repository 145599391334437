import React from 'react';
import FormField from '../../FormField';
import ROUTES from './route';
import NavigationButton from '../../NavigationButton';
import Question from '../../Question';

const QuestionOne = () =>
  <>
    <div className='form-field'>
      <label>
        Have you used any of these thinking strategies yet?
      </label>
    </div>
    <FormField name='thought-replacement'/>
    <FormField name='letting-go'/>
    <FormField name='not-used-thinking-strategies'/>
    <NavigationButton
      layout='left'
      pathRight={ROUTES.QUESTION_ONE_ANSWER}/>
  </>;

export default Question({})(QuestionOne);